import { createContext, useReducer } from "react";

const initialState: any = {
  conversation: {
    type: "",
    categories: [],
    prompt: ''
  },
  subscription: {
    type: 'free',
    hasPaid: false
  },
};

export const GlobalContext = createContext(initialState);

const reducer = (state: any, action: any) => {
  switch (action.type) {
    case "conversation":
      return {
        ...state,
        conversation: {...state.conversation, type: action?.payload},
      };
    case "conversation-set-categories":
      return {
        ...state,
        conversation: { ...state.conversation, categories: action.payload },
      };
    case 'subscription-type':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          type: action?.payload
        }
      }
    case 'subscription-has-paid':
      return {
        ...state,
        subscription: {
          ...state.subscription,
          hasPaid: action?.payload
        }
      }
    case 'conversation-set-prompt':
      return {
        ...state,
        conversation: {
          ...state.conversation,
          prompt: action?.payload
        }
      }
    default:
      return {
        state,
      };
  }
};

export const GlobalContextProvider = (props: any) => {
  const [state, dispatch] = useReducer<any>(reducer, initialState);
  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {props.children}
    </GlobalContext.Provider>
  );
};
