import { PDFOptions } from "constant";
import * as d3 from "d3";
import { useApiCall, useWindowDimensions } from "hooks";
import Tooltip from "pages/Tooltip";
import queryString from "query-string";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getButterflyAPI } from "services";
import { generatePDF } from "utils";
import logo from "assets/images/Vector.png";
import hardtalks from "assets/images/hardtalks.png";
import say_text from "assets/images/say_text.png";
import "./index.css";
const Header = () => {
  return (
    <>
      <div className="betabar lg:w-[394px] lg:h-[33px] ssm:w-[200px] -top-[20px]      -right-[40px] ssm:h-3 sm:h-3 bg-white absolute lg:top-[-30px] lg:right-[-80px]   transform  origin-left"></div>
      <div
        className="flex header flex-row justify-between h-[67px] w-full "
        id="header"
      >
        <div className="flex flex-row justify-start lg:p-0 text-white flex-1 lg:h-[67px] lg:w-[310px] ssm:h-[32px]   ssm:p-[18px]  ">
          <img
            src={logo}
            alt="Left Stripe"
            width="73px"
            height="67px"
            id="logoImg"
          />
          <div className="flex flex-col justify-start ml-3">
            <div className="lg:mb-[20px] ssm:mb-[10px]">
              <img
                src={hardtalks}
                alt="hardtalks"
                width="224px"
                height="28px"
                id="hardtalks"
              />
            </div>
            <img
              src={say_text}
              alt="say_text"
              width="108px"
              height="18px"
              id="say_text"
            />
          </div>
        </div>
        <div className="flex flex-row justify-center text-white w-[83px] h-[48px]  font-extrabold lg:text-[32px] ssm:text-[15px]  transform betabar origin-right lg:-translate-y-[-16px] lg:-translate-x-[50px] ssm:translate-y-[70%] ssm:-translate-x-[0%] ">
          BETA
        </div>
      </div>
    </>
  );
};

export default Header;
