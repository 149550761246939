import React from "react";

import { Modal } from "flowbite-react";

interface ModalExpiredProps {
  show: boolean;
  setShow: (show: boolean) => void;
}

const ModalExpired: React.FC<ModalExpiredProps> = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      size={"2xl"}
      onClose={() => setShow(false)}
      position="center"
      className="[&>div]:!select-none [&>div]:outline-none"
    >
      <div className="px-10 py-4 flex justify-between items-center bg-sky-400">
        <div className="lg:text-[34px] ssm:text-[20px] text-white font-bold">
          Warning
        </div>
      </div>
      <Modal.Body className="bg-sky-400 w-full flex flex-col justify-center pb-20">
        <div className="flex flex-col justify-center pt-4 ">
          <div className="text-center text-white">
            <h1 className="text-[20px] font-bold">
              Time is up! Thank you for using our tool
            </h1>
            <span className="text-[18px]">
              You will be redirected to the start of the website
            </span>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalExpired;
