import { useApiCall, useWindowDimensions } from "hooks";
import { useEffect, useMemo, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Checkbox } from "flowbite-react";

interface RadioProps {
  title: string;
  focused?: string;
  handle?: any;
  height?: number | string; //smallscreen height
}
const Radio: React.FC<RadioProps> = ({
  title,
  focused = false,
  handle,
  height = 10,
}) => {
  return (
    <div
      className={`flex flex-row text-white w-full  lg:h-20 ssm:h-${height} justify-start items-center rounded bg-transparent m-2 px-3 lg:border-2 ssm:border-[1.5px] border-white     hover:shadow-black hover:border-black cursor-pointer ${
        focused == title ? "focused" : ""
      }`}
      onClick={() => handle(title)}
    >
      <label className=" focus:ring-black-500 flex flex-row  lg:pl-4 ssm:pl-0 text-xl cursor-pointer w-4/5  lg:text-lg sm:text-sm ssm:text-[14px]">
        {title}
      </label>
      <div className="flex flex-row justify-end flex-1">
        <input
          type="radio"
          value=""
          name="default-radio"
          className={` cursor-pointer w-5 h-5 text-black-600  bg-transparent border-2  focus:ring-black-500 dark:focus:ring-black-600 dark:ring-offset-gray-800 ${
            focused == title ? "border-black bg-white border-[5px]" : ""
          }  ${focused != title ? "border-white " : ""}
        `}
          onClick={() => handle(title)}
        ></input>
      </div>
    </div>
  );
};

export default Radio;
