import { Route, Routes } from "react-router-dom";
import { Home, Subscription, Conversion, Other, Voice } from "pages";

const RootRoutes = () => {
    return (
    <>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="subscription" element={<Subscription/>}/>
            <Route path="conversion" element={<Conversion/>}/>
            <Route path="voice" element={<Voice/>}/>
        </Routes>
    </>
  );
};

export default RootRoutes;
