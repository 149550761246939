import { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import Radio from "components/radio";
import "./index.css";
import { GlobalContext } from "providers/globalcontext";
import { Modal } from "flowbite-react";
import closeIcon from "assets/images/bx_x.png";
import gsap from "gsap";
import { Header } from "pages";

import { toast } from "react-toastify";
import ModalExpired from "components/modal/modal-expired";

interface DataItem {
  Category: string;
  // Add other properties as needed
}

const Conversion = () => {
  const { state, dispatch } = useContext(GlobalContext);
  const [category, setCategory] = useState<string[]>([]);
  const [prompts, setPrompts] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [otherPrompt, setOtherPrompt] = useState<string>("");
  const [twoTimes, setTwoTimes] = useState("");
  const visitorId = localStorage.getItem("visitorId");
  const [showExpired, setShowExpired] = useState<boolean>(false);

  useEffect(() => {
    if (twoTimes === "twoTImes") {
      setShowExpired(true);
      setTimeout(() => {
        navigate("/subscription");
      }, 3000);
    }
  }, [twoTimes]);

  const checkPaymentStatus = async () => {
    try {
      const response = await fetch("https://hardtalks.ai/api/hasPayed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          visitorId: visitorId,
        }),
      });
      const data = await response.json();
      setTwoTimes(data.hasPayed);
    } catch (error) {
      console.error("Error checking payment status:", error);
    }
  };

  useEffect(() => {
    checkPaymentStatus();

    fetch("https://hardtalks.ai/api/prompt/list")
      .then((res) => res.json() as Promise<DataItem[]>)
      .then((data) => {
        setPrompts(data);
        const categories = data.map((item) => item.Category);
        const uniqueCategories = Array.from(new Set(categories));
        setCategory(uniqueCategories);
        dispatch({ type: "conversation-set-categories", payload: data });
      });
  }, []);

  const navigate = useNavigate();
  const {
    conversation: { type },
  } = state;
  const [isshowOther, setIsshowOther] = useState(false);
  const elementRef = useRef(null);
  const otherelementRef = useRef(null);
  const modalRef = useRef(null);
  useEffect(() => {
    // GSAP animation from bottom to top
    gsap.fromTo(
      elementRef.current,
      { y: "100%" }, // Start from bottom (you might need to adjust this depending on your layout)
      { y: "30%", duration: 1, ease: "power3.out" } // Move to original position at top
    );
  }, []);

  useEffect(() => {
    // GSAP animation from bottom to top
    if (isshowOther)
      gsap.fromTo(
        elementRef.current,
        { y: "30%" }, // Start from bottom (you might need to adjust this depending on your layout)
        { y: "0%", duration: 1, ease: "power3.out" } // Move to original position at top
      );
    gsap.fromTo(
      otherelementRef.current,
      { y: "100%" }, // Start from bottom (you might need to adjust this depending on your layout)
      { y: "0%", duration: 1, ease: "power3.out" } // Move to original position at top
    );
  }, [isshowOther]);
  const handletype = (focusedtype: string) => {
    setSelectedCategory(focusedtype);
    dispatch({ type: "conversation", payload: focusedtype });
    if (focusedtype === "Others") {
      setIsshowOther(true);
    } else {
      setIsshowOther(false);
      setOpenModal(true);
    }
  };

  const [work, setWork] = useState("");

  const handledescription = (item: any) => {
    setWork(item.Description);
    dispatch({ type: "conversation-set-prompt", payload: item.Prompt });
  };

  const handlevoice = () => {
    if (!prompts) {
      toast.error("You should choose!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      return;
    }
    navigate("/voice");
  };
  const [openModal, setOpenModal] = useState<boolean>(false);
  useEffect(() => {
    // GSAP animation from bottom to top
    if (openModal)
      gsap.fromTo(
        elementRef.current,
        { y: "100%" }, // Start from bottom (you might need to adjust this depending on your layout)
        { y: "30%", duration: 1, ease: "power3.out" } // Move to original position at top
      );
  }, [openModal]);
  const handleSlider = (e: any) => {
    setNumber(e.target.value);
  };
  const [number, setNumber] = useState(50);

  const sliderStyle = {
    background: `linear-gradient(to right, black ${number}%,rgba(60, 60, 67, 0.18) ${number}%)`,
  };

  const handleItemClick = (item: any) => {
    setWork(item);
  };

  useEffect(() => {
    if (type !== "") {
      setSelectedCategory(type);
      setOpenModal(true);
    }
  }, []);

  const handleChangeOtherPrompts = (value: string) => {
    setOtherPrompt(value);
  };

  const addOtherPromp = async () => {
    try {
      if (!otherPrompt) {
        toast.error("You should choose!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        return;
      }

      let response = await fetch(`https://hardtalks.ai/api/wishlist/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ prompt: otherPrompt }),
      });

      if (response.ok) {
        dispatch({ type: "conversation-set-prompt", payload: otherPrompt });
        navigate("/voice");
      }
    } catch (error) {
      console.warn(error);
    }
  };

  return (
    <div className="flex w-[100vw] h-[100vh] mx-auto justify-center">
      <ModalExpired show={showExpired} setShow={setShowExpired} />
      <div className="flex flex-col relative  overflow-x-hidden overflow-y-hidden bg-[#3AB4EE] xl:w-[1512px] w-full h-full lg:h-[100vh] lg:pt-[40px] lg:pl-[59px] p-0">
        <div className="lg:flex ">
          <Header />
        </div>
        <div className="-mt-16 flex flex-1 overflow-y-hidden overflow-x-hidden">
          <div
            ref={elementRef}
            className="flex flex-col w-full lg:px-44 justify-start "
          >
            <div className="flex flex-row justify-center text-center text-white font-bold lg:text-[34px] ssm:text-[16px] ssm:mt-[40px] mt-5 lg:h-24 ssm:px-8">
              What tough conversation would you like to rehearse about?
            </div>
            <div className="flex  justify-center items-start mt-3  overflow-y-auto lg:h-[100px]  ">
              <div className="flex flex-col ssm:flex-col lg:flex-row md:flex-col sm:flex-col items-center ssm:w-[150px]  sm:w-3/5 lg:w-full">
                {category.map((item: any) => (
                  <Radio title={item} focused={type} handle={handletype} />
                ))}
                {<Radio title="Others" focused={type} handle={handletype} />}
              </div>
            </div>
            <Modal
              show={openModal}
              size={"7xl"}
              id="addLibraryModal"
              onClose={() => setOpenModal(false)}
              ref={modalRef}
            >
              <div className="lg:p-10 flex justify-between items-center rounded-t dark:border-gray-600 py-4 px-2 bg-sky-400">
                <div className="lg:text-[34px] ssm:text-[20px] text-white font-bold">
                  {type}
                </div>
                <div className="ml-auto">
                  <button
                    aria-label="Close"
                    className="inline-flex items-center p-1 text-white lg:text-[16px] ssm:text-sm"
                    type="button"
                    onClick={() => setOpenModal(false)}
                  >
                    <span className="mr-0 pr-0">Close</span>
                    <img
                      src={closeIcon}
                      width="48px"
                      height="48px"
                      id="closeImg"
                    />
                  </button>
                </div>
              </div>

              <Modal.Body
                className="lg:pb-14 lg:pt-0 ssm:px-2 ssm:h-[500px] lg:h-[372px] bg-sky-400  ssm:pt-4
                 w-full flex flex-col justify-center overflow-x-hidden  overflow-y-hidden relative h-full"
              >
                <div className="flex lg:flex-row sm:flex-col-reverse ssm:flex-col-reverse justify-between items-center w-full px-4 ssm:h-[400px] gap-4">
                  <div className="lg:ml-10 ssm:ml-2 lg:h-[300px] h-full  grid grid-cols-1 justify-center lg:w-1/3 sm:w-full ssm:w-full ssm:flex-1 overflow-x-hidden overflow-y-auto ssm:max-h-[200px] lg:max-h-[300px]">
                    {prompts
                      .filter((item) => item.Category === selectedCategory)
                      .map((item) => (
                        <div
                          className={`flex flex-col justify-center items-center w-full p-2`}
                        >
                          <Radio
                            title={item.Name}
                            focused={work}
                            height={16}
                            handle={() => handledescription(item)}
                          />
                        </div>
                      ))}
                  </div>
                  <div className="lg:mt-[16px] ssm:ml-2 flex h-full justify-center lg:flex-1 lg:h-full  sm:w-full ssm:w-full px-4 ssm:h-full ssm:max-h-[200px] lg:max-h-[300px]">
                    <div className="flex flex-col lg:p-2 justify-start lg:h-[300px] bg-white rounded-lg text-center">
                      <div className="lg:text-3xl ssm:mt-[20px] ssm:text-[20px] font-bold text-sky-500 ssm:pt-2">
                        Description
                      </div>
                      <div className="lg:text-[14px] lg:mt-10 ssm:mt-[15px] ssm:p-4 ssm:pb-8 font-bold ssm:mt-[-12px] ssm:text-[10px] lg:w-[503px]">
                        {work}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ssm:mb-[-15px] flex flex-col justify-center lg:mt-[10px] pt-4 ">
                  <div className="lg:text-lg ssm:text-[12px] text-center ">
                    Before starting, make sure you have a microphone connected
                    to your computer!
                  </div>
                  <div className="flex flex-row justify-center">
                    <button
                      type="button"
                      onClick={() => handlevoice()}
                      className="flex flex-row justify-center items-center rounded-full mt-2 bg-black   shadow-black-500/50  ssm:w-full sm:w-full lg:w-[503px] lg:h-[64px] ssm:h-10 text-white  hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium  text-lg"
                    >
                      <p className="mx-4 lg:text-[20px] ssm:text-[16px]">
                        Start
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="white"
                        viewBox="0 0 24 24"
                        stroke="white"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer
                className=" ssm:py-[10px] bg-sky-400 ssm:border-0
                 w-full flex flex-col justify-center overflow-x-hidden relative h-full"
              ></Modal.Footer>
            </Modal>
            {isshowOther ? (
              <div
                ref={otherelementRef}
                className="flex flex-col w-full px-[32px]  justify-center"
              >
                <div className="flex flex-col justify-center lg:mt-20 ssm:mt-4 items-center font-normal lg:text-[24px] ssm:text-[16px]  flex-1">
                  <div className="text-center font-bold w-full">
                    Write a situation you would like to train about
                  </div>
                  <div className="flex flex-row mt-2  bg-transparent  w-full   text-2xl mb-2">
                    <textarea
                      id="first_name"
                      rows={4}
                      className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="I want to improve my negotiation skills with the car dealer."
                      required
                      onChange={(event) =>
                        handleChangeOtherPrompts(event.target.value)
                      }
                    >
                      {" "}
                    </textarea>
                  </div>

                  <div className="lg:text-[16px] ssm:text-sm text-center  lg:mt-10">
                    Before starting, make sure you have a microphone connected
                    to your computer!
                  </div>
                  <button
                    type="button"
                    onClick={addOtherPromp}
                    className="flex flex-row justify-center items-center rounded-full mt-2  shadow-lg shadow-black-500/50     ssm:text-[16px]
           lg:w-[503px] lg:h-[64px] text-white bg-black hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium sm:w-full ssm:w-full ssm:h-10"
                  >
                    <p className="mx-4 lg:text-[20px]">Start</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="white"
                      viewBox="0 0 24 24"
                      stroke="white"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Conversion;
