import FingerprintJS from "@fingerprintjs/fingerprintjs-pro";
import { useRef, useEffect } from 'react';

const useVisitorId = () => {
  const visitorIdRef = useRef<string | null>(null);

  // Flag to track the first initialization
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (!localStorage.getItem("visitorId") && isFirstRun.current) {

      const fpPromise = FingerprintJS.load({
        apiKey: "qhqngR9hN0KGvdyUZ8JP",
        region: "eu"
      });

      isFirstRun.current = false; // Mark subsequent runs as not first

      fpPromise
        .then(fp => fp.get())
        .then(async result => {
          localStorage.setItem('visitorId', result.visitorId);
          visitorIdRef.current = result.visitorId;
        })
        .catch(error => {
          console.error('Error fetching visitor ID:', error);
          // Handle errors gracefully (e.g., provide a fallback mechanism)
        });
    }
  }, []);

  return visitorIdRef.current;
};

export default useVisitorId;
