import React from 'react';
import './index.css';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo';

interface MicrophoneModalProps {
  recordCancel: () => void;
  stop: () => void;
}

const MicrophoneModal: React.FC<MicrophoneModalProps> = ({ recordCancel, stop }) => {
  return (
    <div className="voice-mask">
      <div className="voice-loading">
        <div className="voice-listening">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave"></div>
        </div>
        <div className="voice-button">
          <button  className="button left" onClick={stop}>
            <SendIcon />
            <p className='button-text'>Send</p>
          </button>
          <button className="button right" onClick={recordCancel}>
            <UndoIcon />
            <p className='button-text'>Back</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MicrophoneModal;
