import React from 'react';
import './index.css'; // Importing the CSS file


const Loading = () => {

    return (
        <div className="mask">
            <div className="loading">
                <img src="/soso.gif" alt="" width="100%" />
                Loading...
            </div>
      </div>
    )
}

export default Loading;

