import React from 'react';
import RootRoutes from './routes';
import getVisitorId from './utils/getVisitorId'; // Updated getVisitorId function
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  getVisitorId(); // Call getVisitorId on component mount

  return (
    <>
      <RootRoutes />
      <ToastContainer />
    </>
  );
}

export default App;
