import setting from "assets/images/setting.png";
import x from "assets/images/x.png";
import { Howl } from "howler";
import { Header } from "pages";
import say_text from "assets/images/say_text.png";
import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import { GlobalContext } from "../../providers/globalcontext";
import MicRecorder from "mic-recorder-to-mp3";
import { GANDER_OPTIONS, LANGUAGE_OPTIONS } from "../../utils/constants";
import {
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
  FormControl,
} from "@mui/material";
import { toast } from "react-toastify";
import KeyboardVoiceIcon from "@mui/icons-material/KeyboardVoice";
import Loading from "components/loading";
import Microphone from "components/Voice/microphone";
import ModalExpired from "components/modal/modal-expired";
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const Voice = () => {
  const setting_ref = useRef<HTMLDivElement>(null);
  const vistorId = localStorage.getItem("visitorId");
  const navigate = useNavigate();
  const { state, dispatch } = useContext(GlobalContext);

  // April 04 2024

  const [categories, setCategories] = useState(
    state.conversation.categories[0]?.Category
  );
  const [messageList, setMessageList]: any = useState([]);
  const [gander, setGander] = useState<string>(GANDER_OPTIONS[0].value);
  const [language, setLanguage] = useState<string>(LANGUAGE_OPTIONS[0].value);
  const [activeMicrophone, setActiveMicrophone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [twoTimes, setTwoTimes] = useState("");
  const [showExpired, setShowExpired] = useState<boolean>(false);

  useEffect(() => {
    if (twoTimes === "twoTImes") {
      setShowExpired(true);
      setTimeout(() => {
        navigate("/subscription");
      }, 3000);
    }
  }, [twoTimes]);

  const [microphoneState, setMicrophoneState] = useState({
    isRecording: false,
    isBlocked: false,
  });
  const audioRef = useRef<HTMLDivElement>(null);

  const isFirstRun = useRef(true);

  const startTimer = async () => {
    try {
      await fetch("https://hardtalks.ai/api/api/session/start/" + vistorId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    } catch (error: any) {
      console.warn("GET ERROR FROM API: ", error.message);
    }
  };

  useEffect(() => {
    const checkPaymentStatus = async () => {
      try {
        const response = await fetch("https://hardtalks.ai/api/hasPayed", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            visitorId: localStorage.getItem("visitorId"),
          }),
        });
        const data = await response.json();
        setTwoTimes(data.hasPayed);
      } catch (error) {
        console.error("Error checking payment status:", error);
      }
    };
    checkPaymentStatus();
  }, []);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    startTimer().then((r) => {});
  }, []);

  let hasSentPrompt: boolean = false;

  useEffect(() => {
    if (!state.conversation.prompt || hasSentPrompt) return;

    // Set flag before sending the prompt
    hasSentPrompt = true;
    sendPrompt(state.conversation.prompt);
  }, []);

  const saveTXT = () => {
    let textToSave = "";
    if (messageList.length > 1) {
      for (let index = 1; index < messageList.length; index++) {
        textToSave +=
          messageList[index]?.role +
          "\n" +
          messageList[index]?.content +
          "\n \n";
      }

      const blob = new Blob([textToSave], { type: "text/plain;charset=utf-8" });
      const downloadLink = document.createElement("a");
      downloadLink.download = "Conversation";
      downloadLink.href = window.URL.createObjectURL(blob);
      downloadLink.click();
    } else {
      toast.error("Don't have any data", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
    }
  };
  const handleGanderChange = (event: SelectChangeEvent<string>) => {
    setGander(event.target.value);
  };
  const handleLanguageChange = (event: SelectChangeEvent<string>) => {
    setLanguage(event.target.value);
  };

  const handleCategoriesChange = (event: SelectChangeEvent<string>) => {
    setCategories(event.target.value);
    dispatch({ type: "conversation", payload: event.target.value });
    navigate("/conversion");
  };

  const recordCancel = () => {
    Mp3Recorder.stop();
    setActiveMicrophone(false);
  };
  const intervalId = setInterval(async () => {
    try {
      const response = await fetch(
        "https://hardtalks.ai/api/api/session/status/" + vistorId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      if (responseData.redirect) {
        localStorage.removeItem("subscriptionType");
        clearInterval(intervalId);
        navigate("/");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }, 30000);

  function settingClick() {
    // Directly modify the `style.display` property
    if (setting_ref.current) {
      setting_ref.current.style.display = "block";
    }
  }

  const start = () => {
    setActiveMicrophone(true);
    if (state.isBlocked) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start().then(() => {
        console.log("recording...");
      });
    }
  };

  const sendAudioFile = async (url: any) => {
    setLoading(true);
    const languageFull = LANGUAGE_OPTIONS.find(
      (option) => option.value === language
    );
    let data = new FormData();
    data.append("file", url);
    data.append("messageList", JSON.stringify(messageList));
    data.append("language", languageFull?.label || "English"); // Provide a default value if languageFull is undefined
    data.append("voice", gander);
    data.append("main_language", language);

    try {
      const response = await fetch("https://hardtalks.ai/api/transcribe", {
        method: "POST",
        body: data,
      });

      if (response.ok) {
        const responseData = await response.json();
        setMessageList(responseData.messageList);
        play(responseData.buffer, audioRef);
        setLoading(false);
      } else {
        throw new Error("Request failed.");
      }
    } catch (error) {
      toast.error("Request failed. Try again", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      setLoading(false);
    }
  };

  const play = (buffer: string, audioRef: React.RefObject<HTMLDivElement>) => {
    try {
      const sound = new Howl({
        src: [`data:audio/mp3;base64,${buffer}`],
        format: ["mp3"],
        html5: true,
        mute: false,
        onplay: () => setIsPlaying(true),
        onend: () => setIsPlaying(false),
      });
      sound.play();
    } catch (error) {
      console.error("An error occurred while playing audio:", error);
    }
  };

  const stopRecording = async () => {
    try {
      setActiveMicrophone(false);
      // Stop recording and get the MP3 buffer and blob
      const [buffer, blob] = await Mp3Recorder.stop().getMp3();

      // Create a WAV file from the blob
      const wavFile = new File([blob], "incomingaudioclip.wav");

      // Send the audio file
      await sendAudioFile(wavFile);
      console.log("test");
      console.log("audio file sent");

      // Update microphone state
      setMicrophoneState({ ...microphoneState, isRecording: false });
    } catch (error) {
      // Handle any errors that occurred during the process
      console.error("An error occurred:", error);
    }
  };

  const sendPrompt = async (val: string) => {
    setLoading(true);
    const currentLanguage = LANGUAGE_OPTIONS.find(
      (option) => option.value === language
    );
    const data = {
      prompt: val,
      messageList: [],
      language: currentLanguage?.label,
      voice: gander,
      main_language: currentLanguage?.value,
    };

    try {
      const response = await fetch(`https://hardtalks.ai/api/prompt`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      if (responseData.messageList && responseData.buffer) {
        setMessageList([...messageList, responseData.messageList[1]]);
        play(responseData.buffer, audioRef);
      } else {
        throw new Error("Invalid response data");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error sending prompt:", error);
      toast.error("Request failed. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      setLoading(false);
    }
  };

  const summary = async () => {
    const languageFull = LANGUAGE_OPTIONS.find(
      (option) => option.value === language
    );
    if (messageList.length > 1) {
      let data = {
        prompt: "Give me summary",
        messageList: messageList,
        language: languageFull,
        voice: gander,
        main_language: language,
      };

      try {
        setLoading(true);

        let response = await fetch(`https://hardtalks.ai/api/summary`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          let responseData = await response.json();
          setMessageList(responseData.messageList);
          // //////////////////////////////////
          play(responseData.buffer, audioRef);
        } else {
          throw new Error("Request failed.");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error:", error);
        toast.error("Request failed. Please try again", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
        });
        setLoading(false);
      }
    } else {
      toast.error("Your conversation is not enough", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
      });
      setLoading(false);
    }
  };

  function closeClick() {
    // Directly modify the `style.display` property
    if (setting_ref.current) {
      setting_ref.current.style.display = "none";
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex w-[100vw] h-[100vh] mx-auto justify-center">
          <div className="flex flex-col relative overflow-x-hidden  overflow-y-hidden bg-[#3AB4EE] xl:w-[1512px] w-full h-full lg:h-[100vh] lg:pt-[40px] lg:pl-[59px] p-0">
            <div className="lg:flex ssm:hidden">
              <Header />
            </div>
            <div className="flex absolute lg:top-[74px] lg:left-[127px]  sm:w-fulls w-full overflow-y-hidden overflow-x-hidden">
              <div
                className="
              flex
              flex-col
              justify-between
              w-full
              h-full
              ssm:h-[700px]
              lg:w-[1262px]
              lg:h-[864px]
              lg:rounded-lg
              bg-[#0C123C]
              lg:p-4 p-0"
              >
                <div className="top-[89px] left-[146px] lg:flex ssm:hidden">
                  <img
                    src={say_text}
                    alt="say_text"
                    width="108px"
                    height="18px"
                    id="say_text"
                  />
                </div>
                <div className="hidden flex-row justify-between text-white text-[16px] leading-6 font-medium m-2 pl-7 pr-7 lg:flex sm:flex">
                  <div className="flex flex-row items-center">
                    <InputLabel
                      id="color-select-label"
                      style={{ color: "white" }}
                    >
                      Choose the topic you want to train on:
                    </InputLabel>
                    <Select
                      labelId="color-select-label"
                      id="color-select"
                      defaultValue={categories}
                      value={categories}
                      onChange={handleCategoriesChange}
                      style={{
                        color: "white",
                        border: "1px solid white",
                        marginLeft: "10px",
                      }}
                    >
                      {state.conversation.categories.map(
                        (item: any, index: any) => (
                          <MenuItem key={index} value={item.Category}>
                            {item.Category}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </div>
                  <div className="flex flex-row items-center">
                    <InputLabel
                      id="color-select-label"
                      style={{ color: "white" }}
                    >
                      AI Voice:
                    </InputLabel>
                    <Select
                      labelId="color-select-label"
                      id="color-select"
                      defaultValue={gander}
                      value={gander}
                      onChange={handleGanderChange}
                      style={{
                        color: "white",
                        border: "1px solid white",
                        marginLeft: "10px",
                      }}
                    >
                      {GANDER_OPTIONS.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="flex flex-row items-center">
                    <InputLabel
                      id="Language-select-label"
                      style={{ color: "white" }}
                    >
                      Language:
                    </InputLabel>
                    <Select
                      labelId="Language-select-label"
                      id="color-select"
                      defaultValue={language}
                      value={language}
                      onChange={handleLanguageChange}
                      style={{
                        color: "white",
                        border: "1px solid white",
                        marginLeft: "10px",
                      }}
                      native={false}
                    >
                      {LANGUAGE_OPTIONS.map((item, index) => (
                        <MenuItem key={index} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
                <div
                  ref={setting_ref}
                  style={{ display: "none" }}
                  className="flex fixed left-0 top-0 flex-col w-full h-[257px] bg-[#3AB4EE] shadow-[0px_4px_9px_0px_#FFFFFF40] lg:hidden ssm:flex"
                >
                  <div className="flex flex-row-reverse m-2">
                    <button
                      type="button"
                      onClick={closeClick}
                      className="w-[80px] h-[24px] text-black bg-transparent text-[14px] leading-5 font-bold  rounded-full text-sm text-center inline-flex items-center"
                    >
                      Close
                      <div className="w-[15px]"></div>
                      <img src={x} alt="x" width="24px" height="24px" />
                    </button>
                  </div>

                  {/* Mobile */}
                  <div className="flex flex-col justify-aroundtext-[14px] font-medium mt-6 pd-6 mb-8 w-full">
                    <div className="flex flex-row justify-between items-center text-white w-full mb-1">
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "100%",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>
                            {" "}
                            Choose the topic you <br /> want to train on:{" "}
                          </span>
                          <Select
                            labelId="color-select-label"
                            id="color-select"
                            defaultValue={categories}
                            value={categories}
                            onChange={handleCategoriesChange}
                            style={{ color: "white", border: "none" }}
                          >
                            {state.conversation.categories.map(
                              (item: any, index: any) => (
                                <MenuItem key={index} value={item.Category}>
                                  {item.Category}
                                </MenuItem>
                              )
                            )}
                          </Select>
                        </div>
                      </FormControl>
                    </div>
                    <div className="flex flex-row justify-between items-center text-white w-full">
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "100%",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>AI Voice:</span>
                          <Select
                            labelId="color-select-label"
                            id="color-select"
                            defaultValue={gander}
                            value={gander}
                            onChange={handleGanderChange}
                            style={{ color: "white", border: "none" }}
                            native={false}
                          >
                            {GANDER_OPTIONS.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </FormControl>
                    </div>
                    <div className="flex flex-row justify-between items-center text-white w-full">
                      <FormControl
                        variant="standard"
                        sx={{
                          m: 1,
                          width: "100%",
                          display: "flex",
                          alignItems: "end",
                          justifyContent: "space-around",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <span>Language:</span>

                          <Select
                            labelId="Language-select-label"
                            id="color-select"
                            defaultValue={language}
                            value={language}
                            onChange={handleLanguageChange}
                            style={{ color: "white" }}
                            native={false}
                          >
                            {LANGUAGE_OPTIONS.map((item, index) => (
                              <MenuItem key={index} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </div>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-between items-center text-white pl-12 pt-8 pb-6 pr-4 ssm:w-[8/8] ssm:h-[40px] ssm:flex lg:hidden sm:hidden">
                  <div className="flex flex-row items-center justify-around  w-full text-base font-medium">
                    Ai Voice, Language, Choose Topic
                  </div>
                  <button
                    onClick={settingClick}
                    type="button"
                    className="justify-center w-6"
                  >
                    <img
                      src={setting}
                      alt="setting"
                      width="24px"
                      height="24px"
                    />
                  </button>
                </div>
                <div
                  className="flex flex-col border-x-0 border-y-2 lg:my-2 lg:mx-5 h-full font-medium lg:leading-6 ssm:leading-5 lg:text-[16px] ssm:text-[14px] ssm:m-0 h-full border-[#FFFFFF33] text-white"
                  style={{ maxHeight: "500px", overflowY: "auto" }}
                >
                  {messageList &&
                    messageList.map((item: any) => {
                      const classNameMainDiv =
                        item.role === "user"
                          ? "flex flex-row-reverse m-4 border-transparent rounded-lg"
                          : "flex flex-start m-4 border-transparent";
                      const classNameChildrenDiv =
                        item.role === "user"
                          ? "flex flex-row rounded-lg bg-[#FFFFFF1A] p-3 lg:py-[20px] px-[16px] ssm:py-[12px]"
                          : "flex flex-row rounded-lg bg-[#FFFFFF1A] py-[20px] px-[16px]";

                      return (
                        <div
                          key={Date.now() + item.content}
                          className={classNameMainDiv}
                        >
                          <div className={classNameChildrenDiv}>
                            {item.content}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <div ref={audioRef}></div>

                <div className="hidden flex-row justify-between px-9 ssm:hidden lg:flex sm:flex text-[16px] leading-6 font-semibold items-center ">
                  <button
                    onClick={summary}
                    type="button"
                    className="justify-center py-2.5 px-5 w-[447px] h-[44px] me-2  text-[#3AB4EE] bg-trasparent rounded-full border-2 border-[#3AB4EE] hover:bg-transparent hover:text-white focus:z-10 focus:ring-1 focus:outline-none focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center "
                  >
                    Feedback
                  </button>

                  <button
                    type="button"
                    className={`justify-center text-white bg-[#3AB4EE] w-[77px] h-[77px] hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center ${
                      isPlaying ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                    onClick={() => {
                      start();
                    }}
                    disabled={isPlaying}
                  >
                    <KeyboardVoiceIcon
                      className="mic-off-icon"
                      onClick={() => {
                        start();
                      }}
                    />
                  </button>

                  {activeMicrophone && (
                    <Microphone
                      stop={stopRecording}
                      recordCancel={recordCancel}
                    />
                  )}

                  <button
                    type="button"
                    className="justify-center py-2.5 px-5 w-[447px] h-[44px] me-2 text-sm font-medium text-[#3AB4EE] bg-trasparent rounded-full border-2 border-[#3AB4EE] hover:bg-transparent hover:text-white focus:z-10 focus:ring-1 focus:outline-none focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center "
                    onClick={saveTXT}
                  >
                    Export Transcript
                  </button>
                </div>
                <div className="flex flex-col justify-center px-2 ssm:flex lg:hidden sm:hidden mb-6">
                  <div className="flex justify-center m-2">
                    <button
                      type="button"
                      className={`justify-center text-white bg-[#3AB4EE] w-[60px] h-[60px] hover:bg-blue-800 focus:ring-1 focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center ${
                        isPlaying ? "opacity-50 cursor-not-allowed" : ""
                      }`}
                      onClick={() => {
                        start();
                      }}
                      disabled={isPlaying}
                    >
                      <KeyboardVoiceIcon
                        className="mic-off-icon"
                        onClick={() => {
                          start();
                        }}
                      />
                    </button>
                    {activeMicrophone && (
                      <Microphone
                        stop={stopRecording}
                        recordCancel={recordCancel}
                      />
                    )}
                  </div>
                  <div className="smm:mt-[-20px] ssm:mb-[20px] flex flex-row justify-between">
                    <button
                      type="button"
                      className="justify-center ssm:w-[160px] ssm:h-[41px] m-2 text-[14px] leading-5 font-semibold text-[#3AB4EE] bg-transparent rounded-full border-2 border-[#3AB4EE] hover:bg-transparent hover:text-white focus:z-10 focus:ring-1 focus:outline-none focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center "
                      onClick={saveTXT}
                    >
                      Export Transcript
                    </button>
                    <button
                      onClick={summary}
                      type="button"
                      className="justify-center py-2.5 px-5 ssm:w-[160px] ssm:h-[41px] m-2 text-[14px] leading-5 font-semibold text-[#3AB4EE] bg-transparent rounded-full border-2 border-[#3AB4EE] hover:bg-transparent hover:text-white focus:z-10 focus:ring-1 focus:outline-none focus:ring-white focus:text-white dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center "
                    >
                      Feedback
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ModalExpired show={showExpired} setShow={setShowExpired} />
        </div>
      )}
    </>
  );
};

export default Voice;
