import React from "react";

interface CardProps {
  buttonText: string;
  title: string;
  item1: string;
  item2: string;
  item3?: string;
  index: number;
  backgroundColor: string;
  handlePlan: () => void;
  disabled?: boolean;
  buttonClassName?: string;
}

const Card: React.FC<CardProps> = ({
  buttonText,
  title,
  item1,
  item2,
  item3,
  handlePlan,
  backgroundColor,
}) => {
  return (
    <div
      // className="flex flex-col justify-center rounded-3xl overflow-x-hidden overflow-y-hidden bg-white lg:w-[442px] sm:w-[320px] ssm:w-[320px]    lg:h-[442px] ssm:m-[13px] lg:p-8 sm:p-2 ssm:py-3 ssm:px-6"
      className={`flex flex-col justify-center rounded-3xl overflow-x-hidden overflow-y-hidden ${
        backgroundColor || "bg-white"
      } lg:w-[442px] sm:w-[270px] ssm:w-[270px] ssm:h-[210px] lg:h-[442px] ssm:m-[13px] lg:p-8 sm:p-2 ssm:py-3 ssm:px-6`}
    >
      <div className="text-plan flex flex-row justify-center items-center lg:p-4 w-full lg:h-1/2">
        <div className="flex flex-row justify-center items-center lg:text-4xl ssm:text-xl font-bold  text-center  ">
          {title}
        </div>
      </div>
      <div className="flex flex-col justify-center lg:text-lg  ssm:text-sm font-medium ml-8 py-3 lg:h-1/2">
        <div className="flex flex-row justify-start relative">
          <div className="lg:w-2 lg:h-2 sm:w-1 sm:h-1 ssm:w-1 ssm:h-1 bg-black rounded-full absolute lg:top-2 md:top-2 sm:top-2 ssm:top-2"></div>
          <div className="ml-4">{item1}</div>
        </div>
        <div className="flex flex-row justify-start relative">
          <div className="lg:w-2 lg:h-2 sm:w-1 sm:h-1 ssm:w-1 ssm:h-1 bg-black rounded-full absolute lg:top-2 md:top-2 sm:top-2 ssm:top-2"></div>
          <div className="ml-4">{item2}</div>
        </div>
        {item3 && (
          <div className="flex flex-row justify-start relative">
            <div className="lg:w-2 lg:h-2 sm:w-1 sm:h-1 ssm:w-1 ssm:h-1 bg-black rounded-full absolute lg:top-2 md:top-2 sm:top-2 ssm:top-2"></div>
            <div className="ml-4">{item3}</div>
          </div>
        )}
      </div>
      <div className=" text-white rounded-full w-full   flex-1     flex flex-row justify-center  text-2xl font-bold text-center">
        <button
          type="button"
          onClick={handlePlan}
          className="flex flex-row justify-center items-center rounded-full mt-2 bg-black shadow-lg shadow-black-50/50 text-white lg:text-2xl sm:text-xl ssm:text-lg w-full lg:h-[64px] sm:h-[36px] ssm:h-[40px]    hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium  "
        >
          <p className="mx-4">{buttonText}</p>
        </button>
      </div>
    </div>
  );
};

export default Card;
