export const LANGUAGE_OPTIONS = [
    { value: "en", label: "English" },
    { value: "fr", label: "French" },
    { value: "nl", label: "Dutch" },
    { value: "es", label: "Spanish" },
    { value: "it", label: "Italian" },
    { value: "de", label: "German" },
    { value: "pt", label: "Portuguese" }
];

export const GANDER_OPTIONS = [
    { value: "m", label: "Ethan" },
    { value: "f", label: "Luna" },
];


